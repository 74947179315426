import React, { lazy, Suspense } from "react";
import { Navigate } from "react-router";
import SinglePost from "./Components/Molecules/SinglePost/SinglePost.jsx";

const AboutUsPage = lazy(() =>
  import("./Components/Pages/AboutUsPage/AboutUs.page")
);
const Blog = lazy(() => import("./Components/Pages/BlogPage/Blog.page.jsx"));
const Packages = lazy(() => import("./Components/Pages/PackagesPage/Packages.page.jsx"));
const AccountPage = lazy(() =>
  import("./Components/Pages/AccountPage/Account.page")
);
const AddApplicantsPage = lazy(() =>
  import("./Components/Pages/AddApplicantsPage/AddApplicants.page")
);
const EditApplicantPage = lazy(() =>
  import("./Components/Pages/EditApplicantPage/EditApplicant.page")
);
const ApplicantsPage = lazy(() =>
  import("./Components/Pages/ApplicantsPage/Applicants.page")
);
const CartPage = lazy(() => import("./Components/Pages/CartPage/Cart.page"));
const CheckoutPage = lazy(() =>
  import("./Components/Pages/CheckoutPage/Checkout.page")
);
const ContactUsPage = lazy(() =>
  import("./Components/Pages/ContactUsPage/ContactUs.page")
);
const CurrentOrdersPage = lazy(() =>
  import("./Components/Pages/CurrentOrdersPage/CurrentOrders.page")
);
const PaymentPage = lazy(() =>
  import("./Components/Pages/PaymentPage/PaymentPage")
);
const OrderPage = lazy(() => import("./Components/Pages/OrderPage/OrderPage"));
const EditAccountPage = lazy(() =>
  import("./Components/Pages/EditAccountPage/EditAccount.page")
);
const HistoricalOrdersPage = lazy(() =>
  import("./Components/Pages/HistoricalOrdersPage/HistoricalOrders.page")
);
const HomePage = lazy(() => import("./Components/Pages/HomePage/Home.page"));
const Layout = lazy(() => import("./Components/Organisms/Layout/Layout"));
const NewPasswordPage = lazy(() =>
  import("./Components/Pages/NewPasswordPage/NewPassword.page")
);
const NotificationPage = lazy(() =>
  import("./Components/Pages/NotificationPage/Notification.page")
);
const ProfilePage = lazy(() =>
  import("./Components/Pages/ProfilePage/Profile.page")
);
const RateOrderPage = lazy(() =>
  import("./Components/Pages/RateOrderPage/RateOrder.page")
);
const SigninPage = lazy(() =>
  import("./Components/Pages/SigninPage/Signin.page")
);
const SignupPage = lazy(() =>
  import("./Components/Pages/SignUpPage/Signup.page")
);
const SubscriptionConfirmPage = lazy(() =>
  import("./Components/Pages/SubscriptionConfirmPage/SubscriptionConfirm.page")
);
const VerificationPage = lazy(() =>
  import("./Components/Pages/Verificationpage/Verification.page")
);
const ForgotPasswordPage = lazy(() =>
  import("./Components/Pages/ForgotPasswordPage/ForgotPassword.page")
);
const ChooseApplicantsPage = lazy(() =>
  import("./Components/Pages/ChooseApplicantsPage/ChooseApplicants.page")
);
const CartAddApplicantPage = lazy(() =>
  import("./Components/Pages/CartAddApplicantPage/CartAddApplicant.page")
);
const MealsPage = lazy(() =>
  import("./Components/Pages/MealsPage/MealsPage.jsx")
);
const EventPlan = lazy(() =>
  import("./Components/Pages/EventPlanpage/EventPlan.jsx")
);
const TermsPrivacyModalChildren = lazy(() =>
  import("./Components/Organisms/Common/TermsPrivacyModalChildren.jsx")
);
const ReportPage = lazy(() =>
  import("./Components/Pages/ReportPage/ReportPage.jsx")
);

const Loader = <div>Loading...</div>;

export const routes = [
  {
    name: "Home",
    path: "/",
    exact: true,
    element: (
      <Suspense fallback={Loader}>
        <HomePage />
      </Suspense>
    ),
  },
  // {
  //   name: "Page not found",
  //   path: "*",
  //   element: <Navigate to="/" />,
  // },
  
  {
    name: "About",
    path: "/about",
    exact: true,
    element: (
      <Suspense fallback={Loader}>
        <AboutUsPage />
      </Suspense>
    ),
  },
  {
    name: "Contact us",
    path: "/contact",
    exact: true,
    element: (
      <Suspense fallback={Loader}>
        <ContactUsPage />
      </Suspense>
    ),
  },
  {
    name: "Blog",
    path: "/blog",
    exact: true,
    element: (
      <Suspense fallback={Loader}>
        <Blog />
      </Suspense>
    ),
  },
  {
    name: "Packages",
    path: "/packages",
    exact: true,
    element: (
      <Suspense fallback={Loader}>
        <Packages />
      </Suspense>
    ),
  },
  {
    name: "Packages",
    path: "/packages/:id",
    exact: true,
    element: (
      <Suspense fallback={Loader}>
        <Packages />
      </Suspense>
    ),
  },
  {
    name: "Blog post",
    path: "/blog/:id",
    exact: true,
    element: (
      <Suspense fallback={Loader}>
        <SinglePost />
      </Suspense>
    ),
  },
  {
    name: "Meals",
    path: "/meals",
    exact: true,
    element: (
      <Suspense fallback={Loader}>
        <MealsPage />
      </Suspense>
    ),
  },
  {
    name: "Event",
    path: "/event",
    exact: true,
    element: (
      <Suspense fallback={Loader}>
        <EventPlan />
      </Suspense>
    ),
  },
  {
    name: "Notification",
    path: "/notification",
    exact: true,
    element: (
      <Suspense fallback={Loader}>
        <NotificationPage />
      </Suspense>
    ),
  },
  {
    name: "Checkout",
    path: "/checkout",
    exact: true,
    element: (
      <Suspense fallback={Loader}>
        <CheckoutPage />
      </Suspense>
    ),
  },
  {
    name: "Policy",
    path: "/policy",
    exact: true,
    element: (
      <Suspense fallback={Loader}>
        <TermsPrivacyModalChildren />
      </Suspense>
    ),
  },
  {
    name: "Profile",
    path: "/profile",
    exact: true,
    element: (
      <Suspense fallback={Loader}>
        <ProfilePage />
      </Suspense>
    ),
    children: [
      {
        name: "My Account",
        path: "/profile/my-account",
        children: [
          {
            name: "Add Applicants",
            path: "/profile/my-account/applicants/add-applicants",
            element: (
              <Suspense fallback={Loader}>
                <AddApplicantsPage />
              </Suspense>
            ),
          },
          {
            name: "Edit Applicants",
            path: "/profile/my-account/applicants/edit-applicants/:id",
            element: (
              <Suspense fallback={Loader}>
                <EditApplicantPage />
              </Suspense>
            ),
          },
          {
            name: "Applicants",
            path: "/profile/my-account/applicants",
            element: (
              <Suspense fallback={Loader}>
                <ApplicantsPage />
              </Suspense>
            ),
          },
          {
            name: "Account",
            path: "/profile/my-account/account-setting",
            element: (
              <Suspense fallback={Loader}>
                <AccountPage />
              </Suspense>
            ),
          },
          {
            name: "Edit Account",
            path: "/profile/my-account/account-setting/edit",
            element: (
              <Suspense fallback={Loader}>
                <EditAccountPage />
              </Suspense>
            ),
          },
        ],
      },
      {
        name: "My Orders",
        path: "/profile/my-orders",
        children: [
          {
            name: "Report",
            path: "/profile/my-orders/report/:id",
            element: (
              <Suspense fallback={Loader}>
                <ReportPage/>
              </Suspense>
            ),
          },
          {
            name: "Orders",
            path: "/profile/my-orders/order",
            element: (
              <Suspense fallback={Loader}>
                <OrderPage />
              </Suspense>
            ),
          },
          {
            name: "Orders",
            path: "/profile/my-orders/payment",
            element: (
              <Suspense fallback={Loader}>
                <PaymentPage />
              </Suspense>
            ),
          },
          {
            name: "Current Orders",
            path: "/profile/my-orders/current-orders",
            element: (
              <Suspense fallback={Loader}>
                <CurrentOrdersPage />
              </Suspense>
            ),
          },
          {
            name: "History",
            path: "/profile/my-orders/history",
            element: (
              <Suspense fallback={Loader}>
                <HistoricalOrdersPage />
              </Suspense>
            ),
          },
        ],
      },
    ],
  },
  {
    name: "Cart",
    path: "/cart",
    exact: true,
    element: (
      <Suspense fallback={Loader}>
        <CartPage />
      </Suspense>
    ),
    children: [
      {
        name: "Choose Applicants",
        path: "/cart/choose-applicants",
        element: (
          <Suspense fallback={Loader}>
            <ChooseApplicantsPage />
          </Suspense>
        ),
      },
      {
        name: "Add Applicant Cart",
        path: "/cart/add-applicant",
        element: (
          <Suspense fallback={Loader}>
            <CartAddApplicantPage />
          </Suspense>
        ),
      },
    ],
  },
  {
    name: "Subscription confirmed",
    path: "/sub-successful",
    element: (
      <Suspense fallback={Loader}>
        <SubscriptionConfirmPage />
      </Suspense>
    ),
  },
  {
    name: "Rate order",
    path: "/add-review/:id",
    element: (
      <Suspense fallback={Loader}>
        <RateOrderPage />
      </Suspense>
    ),
  },
];

export const authenticationRoutes = [
  {
    name: "Home",
    path: "/",
    exact: true,
    element: (
      <Suspense fallback={Loader}>
        <Layout>
          <HomePage />
        </Layout>
      </Suspense>
    ),
  },
  {
    name: "Sign up",
    path: "/signup",
    exact: true,
    element: (
      <Suspense fallback={Loader}>
        <SignupPage />
      </Suspense>
    ),
  },
  {
    name: "Sign in",
    path: "/signin",
    exact: true,
    element: (
      <Suspense fallback={Loader}>
        <SigninPage />
      </Suspense>
    ),
  },
  {
    name: "About",
    path: "/about",
    exact: true,
    element: (
      <Suspense fallback={Loader}>
        <Layout>
          <AboutUsPage />
        </Layout>
      </Suspense>
    ),
  },
  {
    name: "Contact us",
    path: "/contact",
    exact: true,
    element: (
      <Suspense fallback={Loader}>
        <Layout>
          <ContactUsPage />
        </Layout>
      </Suspense>
    ),
  },
  {
    name: "Forget password",
    path: "/forgetPassword",
    exact: true,
    element: (
      <Suspense fallback={Loader}>
        <ForgotPasswordPage />
      </Suspense>
    ),
  },
  {
    name: "Blog",
    path: "/blog",
    exact: true,
    element: (
      <Suspense fallback={Loader}>
        <Layout>
          <Blog />
        </Layout>
      </Suspense>
    ),
  },
  {
    name: "Packages",
    path: "/packages",
    exact: true,
    element: (
      <Suspense fallback={Loader}>
        <Layout>
          <Packages />
        </Layout>
      </Suspense>
    ),
  },
  {
    name: "Packages",
    path: "/packages/:id",
    exact: true,
    element: (
      <Suspense fallback={Loader}>
        <Layout>
          <Packages />
        </Layout>
      </Suspense>
    ),
  },
  {
    name: "Meals",
    path: "/meals",
    exact: true,
    element: (
      <Suspense fallback={Loader}>
        <Layout>
          <MealsPage />
        </Layout>
      </Suspense>
    ),
  },
  {
    name: "Event",
    path: "/event",
    exact: true,
    element: (
      <Suspense fallback={Loader}>
        <Layout>
          <EventPlan />
        </Layout>
      </Suspense>
    ),
  },
  {
    name: "Blog post",
    path: "/blog/:id",
    exact: true,
    element: (
      <Suspense fallback={Loader}>
        <Layout>
          <SinglePost />
        </Layout>
      </Suspense>
    ),
  },
  {
    name: "Policy",
    path: "/policy",
    exact: true,
    element: (
      <Suspense fallback={Loader}>
        <Layout>
          <TermsPrivacyModalChildren />
        </Layout>
      </Suspense>
    ),
  },
  {
    name: "NewPassword",
    path: "/resetpassword",
    exact: true,
    element: (
      <Suspense fallback={Loader}>
        <NewPasswordPage />
      </Suspense>
    ),
  },
  // {
  //   name: "Page not found",
  //   path: "*",
  //   element: <Navigate to="/signin" />,
  // },
];

export const verifyRoutes = [
  {
    name: "Verification",
    path: "/verify",
    exact: true,
    element: (
      <Suspense fallback={Loader}>
        <VerificationPage />
      </Suspense>
    ),
  },
];
