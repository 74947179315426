// import "dayjs/locale/ar";

// import {
//   ActiveCartIcon,
//   CartIcon,
//   LanguageIcon,
//   Logo,
//   NavbarActiveProfile,
//   NotificationsIcon,
//   ProfileIcon,
// } from "../../../utils/IconsSrc";
// import { Link, NavLink } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";

// import DropDownNotification from "../../Atoms/DropDownNotification/DropDownNotification";
// import { LoadingSpinner } from "../../Atoms/LoadingSpinner/LoadingSpinner";
// import axios from "axios";
// import classnames from "classnames";
// import dayjs from "dayjs";
// import { getNotificationsRequest } from "../../../Services/modules/notifications";
// import relativeTime from "dayjs/plugin/relativeTime";
// import { useAuth } from "../../../Hooks/useAuth";
// import { useEffect } from "react";
// import { useLanguage } from "../../../Hooks/useLanguage";
// import { useLocation } from "react-router";
// import { useRef } from "react";
// import { useState } from "react";
// import uselistenForOutsideClicks from "../../../Hooks/useListenForOutsideClicks";
// import { withTranslation } from "react-i18next";
// import { toast } from "react-toastify";

// const Navbar = ({ t, i18n }) => {
//   const { pathname } = useLocation();
//   const language = useLanguage();
//   const { auth } = useAuth();
//   dayjs.extend(relativeTime);
//   const [openNotification, setOpenNotification] = useState(false);
//   const dispatch = useDispatch();
//   const {
//     notifications,
//     load: { isLoading },
//   } = useSelector((state) => state.notifications);
//   const handleOpenNotification = () => {
//     setOpenNotification((currentStatus) => !currentStatus);
//     if (!openNotification && pathname !== "/notification") {
//       dispatch(getNotificationsRequest(1, 4, true));
//     }
//   };
//   // Hide Dropdown on Outside Click
//   const menuRef = useRef(null);
//   const [listening, setListening] = useState(false);
//   // const handleOptionClick = () => {
//   //   setOpenNotification(false);
//   // };
//   useEffect(
//     uselistenForOutsideClicks(
//       listening,
//       setListening,
//       menuRef,
//       setOpenNotification
//     ),
//     []
//   );

//   const onLanguageHandle = (event) => {
//     let newLang = event;
//     i18n.changeLanguage(newLang);
//     if (newLang === "ar") {
//       localStorage.setItem("language", "ar");
//       axios.defaults.headers.common["Accept-Language"] = "ar";
//     } else {
//       localStorage.setItem("language", "en");
//       axios.defaults.headers.common["Accept-Language"] = "en";
//     }

//     window.location.reload();
//   };

//   const navLinks = [
//     { name: t("navbar.home"), href: "/" },
//     {
//       name: t("navbar.meals"),
//       href: "/meals",

//     },
//     {
//       name: t("navbar.EventPlan"),
//       // onClick: () => {
//       //   // toast.warn("Feature Coming Soon");
//       // },
//       href :"/event"
//     },
//     { name: t("navbar.aboutUs"), href: "/about" },
//     { name: t("navbar.contactUs"), href: "/contact" },
//     { name: t("navbar.blog"), href: "/blog" },
//   ];
//   return (
//     <nav
//       className={`shadow-md py-2 flex-col new-header ${
//         auth ? "login" : "logout"
//       } ${language == "ar" ? "arabic" : "english"}`}
//     >
//       <div className="lg:w-[94%] w-[90%] mx-auto md:flex relative justify-between  new-home-banner ">
//         <div className="flex justify-center new-home-logo">
//         <Link to="/">
//             <Logo />
//             {/* <img src{}></img> */}
//           </Link>
//         </div>
//         <div className="flex justify-center  test my-auto flex-wrap new-nav">
//           {navLinks.map((link, index) => (
//             <NavLink
//               key={index}
//               to={link.href}
//               onClick={() => {
//                 if (link.onClick) {
//                   link.onClick();
//                 }
//               }}
//             >
//               {({ isActive }) => (
//                 <span
//                   className={classnames(
//                     "text-[26px] cursor-pointer md:mx-3 lg:mx-5 sm:mx-3 mx-3",
//                     {
//                       "text-orange font-[AraHamahBold]": isActive,
//                       "text-lightgrey font-[AraHamah1964] ": !isActive,
//                     }
//                   )}
//                 >
//                   {link.name}
//                 </span>
//               )}
//             </NavLink>
//           ))}
//         </div>
//         <div ref={menuRef} className="flex my-auto justify-center new-sign">
//           {window.localStorage.getItem("language") === "ar" ? (
//             <div
//               onClick={() => onLanguageHandle("en")}
//               className="cursor-pointer my-auto new-btn-light"
//             >
//               <LanguageIcon /> {!auth ? "لغة" : ""}
//             </div>
//           ) : auth ? (
//             <div
//               onClick={() => onLanguageHandle("ar")}
//               className="cursor-pointer my-auto "
//             >
//               <LanguageIcon />
//             </div>
//           ) : (
//             <div
//               onClick={() => onLanguageHandle("ar")}
//               className="cursor-pointer my-auto new-btn-light"
//             >
//               <LanguageIcon /> Language
//             </div>
//           )}

//           {auth ? (
//             <>
//               <NavLink
//                 to="/profile/my-account/account-setting"
//                 className={"h-[26px] w-[26px] new-svg-path"}
//               >
//                 {pathname.includes("/profile") ? (
//                   <NavbarActiveProfile width={"32px"} height={"32px"} />
//                 ) : (
//                   <ProfileIcon width={"29px"} height={"29px"} />
//                 )}
//               </NavLink>
//               <NavLink
//                 to="/cart/choose-applicants"
//                 className={"h-[26px] w-[26px]"}
//               >
//                 {pathname.includes("/cart") ? (
//                   <ActiveCartIcon width={"29px"} height={"29px"} />
//                 ) : (
//                   <CartIcon width={"29px"} height={"29px"} />
//                 )}
//               </NavLink>
//               <div className=" font-[AraHamah1964]">
//                 <div
//                   className="overflow-hidden cursor-pointer new-svg-path"
//                   onClick={handleOpenNotification}
//                 >
//                   <NotificationsIcon />
//                 </div>
//                 {/* list */}
//                 {openNotification && (
//                   <DropDownNotification
//                     title={t("notification.notifications")}
//                     t={t}
//                     buttonNameStyle="focus:ring-0 text-[28px] py-[10px] font-bold rounded-b-[30px]"
//                     setOpenNotification={setOpenNotification}
//                   >
//                     {isLoading ? (
//                       <LoadingSpinner />
//                     ) : (
//                       notifications?.map((notification, i) => (
//                         <div
//                           key={i}
//                           className={
//                             notification?.is_seen
//                               ? "flex gap-[15px] px-[16px] py-[14px] items-center"
//                               : "flex gap-[15px] px-[16px] py-[14px] bg-[#FEF9F4] items-center"
//                           }
//                         >
//                           <p className="text-[24px] grow">
//                             {localStorage.getItem("language") === "en"
//                               ? notification?.title_en
//                               : notification?.title_ar}
//                           </p>
//                           <h4 className="text-[20px] pr-3">
//                             {language === "ar"
//                               ? dayjs()
//                                   .locale("ar")
//                                   .to(dayjs(notification.created_at))
//                               : dayjs().to(dayjs(notification.created_at))}
//                           </h4>
//                         </div>
//                       ))
//                     )}
//                   </DropDownNotification>
//                 )}
//               </div>
//             </>
//           ) : (
//             <NavLink
//               to="/signin"
//               className={
//                 "text-[26px] cursor-pointer text-orange font-[AraHamahBold] new-btn-primary"
//               }
//             >
//               {t("loginBtn.Signin")}
//             </NavLink>
//           )}
//         </div>
//       </div>
//     </nav>
//   );
// };

// export default withTranslation()(Navbar);


import "dayjs/locale/ar";
import {
  ActiveCartIcon,
  CartIcon,
  LanguageIcon,
  Logo,
  NavbarActiveProfile,
  NotificationsIcon,
  ProfileIcon,
} from "../../../utils/IconsSrc";
import { Link, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DropDownNotification from "../../Atoms/DropDownNotification/DropDownNotification";
import { LoadingSpinner } from "../../Atoms/LoadingSpinner/LoadingSpinner";
import axios from "axios";
import classnames from "classnames";
import dayjs from "dayjs";
import { getNotificationsRequest } from "../../../Services/modules/notifications";
import relativeTime from "dayjs/plugin/relativeTime";
import { useAuth } from "../../../Hooks/useAuth";
import { useEffect, useState, useRef } from "react";
import { useLanguage } from "../../../Hooks/useLanguage";
import { useLocation } from "react-router";
import uselistenForOutsideClicks from "../../../Hooks/useListenForOutsideClicks";
import { withTranslation } from "react-i18next";
import { toast } from "react-toastify";

const Navbar = ({ t, i18n }) => {
  const { pathname } = useLocation();
  const language = useLanguage();
  const { auth } = useAuth();
  dayjs.extend(relativeTime);
  const [openNotification, setOpenNotification] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false); // State for mobile menu
  const dispatch = useDispatch();
  const {
    notifications,
    load: { isLoading },
  } = useSelector((state) => state.notifications);

  const handleOpenNotification = () => {
    setOpenNotification((currentStatus) => !currentStatus);
    if (!openNotification && pathname !== "/notification") {
      dispatch(getNotificationsRequest(1, 4, true));
    }
  };

  // Hide Dropdown on Outside Click
  const menuRef = useRef(null);
  const [listening, setListening] = useState(false);
  useEffect(
    uselistenForOutsideClicks(
      listening,
      setListening,
      menuRef,
      setOpenNotification
    ),
    []
  );

  const onLanguageHandle = (event) => {
    let newLang = event;
    i18n.changeLanguage(newLang);
    if (newLang === "ar") {
      localStorage.setItem("language", "ar");
      axios.defaults.headers.common["Accept-Language"] = "ar";
    } else {
      localStorage.setItem("language", "en");
      axios.defaults.headers.common["Accept-Language"] = "en";
    }
    window.location.reload();
  };

  const navLinks = [
    { name: t("navbar.home"), href: "/" },
    { name: t("navbar.meals"), href: "/meals" },
    { name: t("navbar.aboutUs"), href: "/about" },
    { name: t("navbar.contactUs"), href: "/contact" },
    { name: t("navbar.blog"), href: "/blog" },
    { name: t("navbar.EventPlan"), href: "/event" },
  ];

  return (
    <nav
      className={`shadow-md py-2 flex-col new-header ${
        auth ? "login" : "logout"
      } ${language == "ar" ? "arabic" : "english"}`}
    >
      <div className="lg:w-[94%] w-[90%] mx-auto lg:flex relative justify-between new-home-banner">
        {/* Logo */}
        <div className="flex justify-between items-center new-home-logo">
          <Link to="/">
            <Logo />
          </Link>
          {/* Hamburger Menu for Mobile (Shows below 1024px) */}
          <button
            className="lg:hidden block text-orange focus:outline-none"
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16m-7 6h7"
              ></path>
            </svg>
          </button>
        </div>

        {/* Nav Links */}
        <div
          className={`lg:flex justify-center test my-auto flex-wrap new-nav ${
            isMobileMenuOpen ? "block" : "hidden"
          }`}
        >
          {navLinks.map((link, index) => (
            <NavLink
              key={index}
              to={link.href}
              onClick={() => {
                if (link.onClick) {
                  link.onClick();
                }
                setIsMobileMenuOpen(false); // Close mobile menu on link click
              }}
            >
              {({ isActive }) => (
                <span
                  className={classnames(
                    "text-[26px] cursor-pointer lg:mx-3 xl:mx-5 sm:mx-3 mx-3 block lg:inline-block my-2 lg:my-0",
                    {
                      "text-orange font-[AraHamahBold]": isActive,
                      "text-lightgrey font-[AraHamah1964]": !isActive,
                    }
                  )}
                >
                  {link.name}
                </span>
              )}
            </NavLink>
          ))}
        <div ref={menuRef} className="flex my-auto justify-center new-sign">
          {window.localStorage.getItem("language") === "ar" ? (
            <div
              onClick={() => onLanguageHandle("en")}
              className="cursor-pointer my-auto new-btn-light"
            >
              <LanguageIcon /> {!auth ? "لغة" : ""}
            </div>
          ) : auth ? (
            <div
              onClick={() => onLanguageHandle("ar")}
              className="cursor-pointer my-auto"
            >
              <LanguageIcon />
            </div>
          ) : (
            <div
              onClick={() => onLanguageHandle("ar")}
              className="cursor-pointer my-auto new-btn-light"
            >
              <LanguageIcon /> Language
            </div>
          )}

          {auth ? (
            <>
              <NavLink
                to="/profile/my-account/account-setting"
                className={"h-[26px] w-[26px] new-svg-path"}
              >
                {pathname.includes("/profile") ? (
                  <NavbarActiveProfile width={"32px"} height={"32px"} />
                ) : (
                  <ProfileIcon width={"29px"} height={"29px"} />
                )}
              </NavLink>
              <NavLink
                to="/cart/choose-applicants"
                className={"h-[26px] w-[26px]"}
              >
                {pathname.includes("/cart") ? (
                  <ActiveCartIcon width={"29px"} height={"29px"} />
                ) : (
                  <CartIcon width={"29px"} height={"29px"} />
                )}
              </NavLink>
              {/* <div className="font-[AraHamah1964]">
                <div
                  className="overflow-hidden cursor-pointer new-svg-path"
                  onClick={handleOpenNotification}
                >
                  <NotificationsIcon />
                </div>
                {openNotification && (
                  <DropDownNotification
                    title={t("notification.notifications")}
                    t={t}
                    buttonNameStyle="focus:ring-0 text-[28px] py-[10px] font-bold rounded-b-[30px]"
                    setOpenNotification={setOpenNotification}
                  >
                    {isLoading ? (
                      <LoadingSpinner />
                    ) : (
                      notifications?.map((notification, i) => (
                        <div
                          key={i}
                          className={
                            notification?.is_seen
                              ? "flex gap-[15px] px-[16px] py-[14px] items-center"
                              : "flex gap-[15px] px-[16px] py-[14px] bg-[#FEF9F4] items-center"
                          }
                        >
                          <p className="text-[24px] grow">
                            {localStorage.getItem("language") === "en"
                              ? notification?.title_en
                              : notification?.title_ar}
                          </p>
                          <h4 className="text-[20px] pr-3">
                            {language === "ar"
                              ? dayjs()
                                  .locale("ar")
                                  .to(dayjs(notification.created_at))
                              : dayjs().to(dayjs(notification.created_at))}
                          </h4>
                        </div>
                      ))
                    )}
                  </DropDownNotification>
                )}
              </div> */}
            </>
          ) : (
            <NavLink
              to="/signin"
              className={
                "text-[26px] cursor-pointer text-orange font-[AraHamahBold] new-btn-primary"
              }
            >
              {t("loginBtn.Signin")}
            </NavLink>
          )}
        </div>
        </div>

        {/* Right Side Icons */}
      </div>
    </nav>
  );
};

export default withTranslation()(Navbar);