import classNames from "classnames";
import { useEffect } from "react";

const Modal = ({ setShowModal, showModal, children, modalClassName }) => {
  
  document.addEventListener('keydown', (event) => {
    if (event.code === 'Escape' && showModal === true) {
      setShowModal(!showModal);
    }
  })

  // useEffect(() => {
  //   const closeModal = (event) => {
  //     if (event.key === "Escape") setShowModal(!showModal);
  //   };
  //   window.addEventListener("keydown", (event) => closeModal(event));
  //   return window.removeEventListener("keydown", (event) => closeModal(event));
  // }, [showModal]);
  return (
    <>
      {showModal ? (
        <div
        style={{zIndex:9999}}
          // onClick={() => setShowModal(!showModal)}
          className="fixed top-0 left-0 right-0 z-50  w-full  overflow-x-hidden overflow-y-auto md:inset-0 h-modal  flex justify-center items-center  bg-[#323232]/50 h-full term-cond-modal "
        >
          <div
            className={classNames(
              modalClassName,
              "relative  w-[963px] m-2 max-h-[83vh] overflow-y-scroll  max-w-7xl  bg-white rounded-xl shadow px-2 sm:px-8  xl:pb-8 slider-inner-box choose-applicants"
            )}
          >
            <div
              className={`top-6 ${localStorage.getItem("language") === "ar" ? "leftt-0 mr-auto" : "right-0 ml-auto"} w-[24px] h-[24px] flex items-center justify-center bg-white	rounded-2xl text-3xl sticky cursor-pointer  x-modal`}
              onClick={() => setShowModal(!showModal)}
            >
              x
            </div>
            {children}
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Modal;
