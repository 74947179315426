import AboutUs1 from "../Assets/Images/aboutus1.png";
import AboutUs2 from "../Assets/Images/aboutus2.png";
import AboutUs3 from "../Assets/Images/aboutus3.png";
import AuthImage from "../Assets/Images/authImage.png";
import Characters from "../Assets/Images/characters.webp";
import HomeBannerBackground from "../Assets/Images/HomeBannerBackground.png";
import HomeBannerImage from "../Assets/Images/HomeBannerImage.png";
import MapPic from "../Assets/Images/map.png";
import PackageImage from "../Assets/Images/packageImage.png";
import Service1 from "../Assets/Images/service1.png";
import Service2 from "../Assets/Images/service2.png";
import Service3 from "../Assets/Images/service3.png";
import Summary from "../Assets/Images/summary.png";
import Thumbsdown1 from "../Assets/Images/thumbsDown1.png";
import Thumbsdown2 from "../Assets/Images/thumbsDown2.png";
import WebsiteLogo from "../Assets/Images/logo.webp";
import ChooseUs from "../Assets/Images/chooseUs.png";
import BlogUx from "../Assets/Images/blog-page/blog-view/ux-review.jpg";
import BlogApi from "../Assets/Images/blog-page/blog-view/api-stack.jpg";
import BlogMigrating from "../Assets/Images/blog-page/blog-view/migrating.jpg";
import BlogLoadBtn from "../Assets/Images/blog-page/blog-view/view-load-arrow.svg";
import BlogSingle from "../Assets/Images/blog-single/blog-banner.jpg";
import BlogArticles from "../Assets/Images/blog-single/articles-icon.svg";
import NewBanner from "../Assets/Images/newBanner.png";
import NewBannerFooter from "../Assets/Images/newBannerFooter.png";
import ButtonArrow from "../Assets/Images/arrow_btn.svg";
import AboutBG from "../Assets/Images/about-bg.png";
import MealBg from "../Assets/Images/latest-meals-bg.png";
import storeimg from "../Assets/Images/store-bg.png";
import Whychoosesection from "../Assets/Images/why-choos-heart.png";
import MealBox1 from "../Assets/Images/lunchbox-one.png";
import MealBox2 from "../Assets/Images/lunchbox-two.png";
import MealBox3 from "../Assets/Images/lunchbox-three.png";
import MealBox4 from "../Assets/Images/lunchbox-four.png";
import MealBox5 from "../Assets/Images/lunchbox-five.png";
import QualityImage from "../Assets/Images/quality-img.png";
import SliderImages from "../Assets/Images/slider-img.png";
import waveTop from "../Assets/Images/wave-top.png";
import supportImage from "../Assets/Images/support-img.png";
import Gallery1 from "../Assets/Images/gallery-a.jpg";
import Gallery2 from "../Assets/Images/gallery-b.jpg";
import Gallery3 from "../Assets/Images/gallery-c.jpg";
import Gallery4 from "../Assets/Images/gallery-d.jpg";
import Gallery5 from "../Assets/Images/gallery-e.jpg";
import Gallery6 from "../Assets/Images/gallery-f.jpg";
import Gallery7 from "../Assets/Images/gallery-g.jpg";
import Partner1 from "../Assets/Images/partner-a.png";
import Partner2 from "../Assets/Images/partner-b.png";
import Partner3 from "../Assets/Images/partner-c.png";
import Partner4 from "../Assets/Images/partner-d.png";
import Partner5 from "../Assets/Images/partner-e.png";
import Partner6 from "../Assets/Images/partner-f.png";
import Partner7 from "../Assets/Images/partner-g.png";
import Partner8 from "../Assets/Images/partner-h.png";
import Partner9 from "../Assets/Images/partner-i.png";
import Partner10 from "../Assets/Images/partner-j.png";
import Partner11 from "../Assets/Images/partner-k.png";
import GalleryHov1 from "../Assets/Images/1.png";
import GalleryHov2 from "../Assets/Images/2.png";
import GalleryHov3 from "../Assets/Images/3.png";
import GalleryHov4 from "../Assets/Images/4.png";
import GalleryHov6 from "../Assets/Images/6.png";
import GalleryHov7 from "../Assets/Images/7.png";
import UnderMaintaince from "../Assets/Images/UnderMaintainance.svg";
import Sliderimg2 from "../Assets/Images/SlideImg2.jpg";
import Sliderimg3 from "../Assets/Images/slideimg3.jpg";
import Sliderimg4 from "../Assets/Images/slideImg4.jpg";

const ImagesSrc = {
  WebsiteLogo,
  Characters,
  AboutUs1,
  AboutUs2,
  AboutUs3,
  HomeBannerBackground,
  HomeBannerImage,
  Summary,
  Service1,
  Service2,
  Service3,
  MapPic,
  AuthImage,
  PackageImage,
  Thumbsdown1,
  Thumbsdown2,
  ChooseUs,
  BlogUx,
  BlogApi,
  BlogMigrating,
  BlogLoadBtn,
  BlogSingle,
  BlogArticles,
  NewBannerFooter,
  NewBanner,
  ButtonArrow,
  AboutBG,
  MealBg,
  Whychoosesection,
  storeimg,
  MealBox1,
  MealBox2,
  MealBox3,
  MealBox4,
  MealBox5,
  QualityImage,
  SliderImages,
  waveTop,
  supportImage,
  Gallery1,
  Gallery2,
  Gallery3,
  Gallery4,
  Gallery5,
  Gallery6,
  Gallery7,
  Partner1,
  Partner2,
  Partner3,
  Partner4,
  Partner5,
  Partner6,
  Partner7,
  Partner8,
  Partner9,
  Partner10,
  Partner11,
  GalleryHov7,
  GalleryHov6,
  GalleryHov4,
  GalleryHov3,
  GalleryHov2,
  GalleryHov1,
  UnderMaintaince,
  Sliderimg2,
  Sliderimg3,
  Sliderimg4,
};

export default ImagesSrc;
