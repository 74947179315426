import {
  FooterLogo,
  InovaLogo,
  InstagramIcon,
  LocationIconOrange,
  MailIcon,
  Newfooterlogo,
  SnapchatIcon,
  TaxIcon,
  TiktokIcon,
  TwitterIcon,
  WebIcon,
  WhatsappIcon,
} from "../../../utils/IconsSrc";
// import { logoPng} from "../../../../public/Partners/sfs_Logo.png"
import { Link, useNavigate } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { useState } from "react";
import Modal from "../../Organisms/Common/Modal";
import TermsPrivacyModalChildren from "../../Organisms/Common/TermsPrivacyModalChildren";

// import Button from "../../Atoms/Buttons/Button";
// import Textfield from "../../Atoms/Textfields/Textfield";

const Footer = ({ t }) => {
    const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const lng = localStorage.getItem("language");

  const SocialLinks = [
    {
      text: "311322093900003",
      icon: <TaxIcon className="min-w-[38px]" />,
    },
    {
      // text: " mrcarrot.sa",
      icon: <SnapchatIcon className="min-w-[38px]" />,
    },
    {
      // text: "mrcarrot.sa",
      href: "https://www.tiktok.com/@mrcarrot.sa",
      icon: <TiktokIcon className="min-w-[38px]" />,
    },
    {
      // text: "mrcarrotsa",
      href: "https://twitter.com/mrcarrotsa",
      icon: <TwitterIcon className="min-w-[38px]" />,
    },
    {
      // text: "mrcarrot.co",
      href: "",
      icon: <WebIcon className="min-w-[38px]" />,
    },
    {
      text: "info@mrcarrot.sa",
      icon: <MailIcon className="min-w-[38px]" />,
      href: "mailto:info@mrcarrot.sa",
    },
    {
      // text: "mrcarrot.sa",
      href: "https://www.instagram.com/mrcarrot.sa/",
      icon: <InstagramIcon className="min-w-[38px]" />,
    },
    {
      // text: "+966531270787",
      icon: <WhatsappIcon className="min-w-[38px]" />,
      href: "https://wa.me/966531270787",
    },
    {
      text: t("contactUs.location"),
      icon: <LocationIconOrange className="min-w-[38px]" />,
      href: "https://maps.app.goo.gl/8ktEybaFFqUhbwXg6",
    },
  ];

  return (
    <>
      <footer>
        <div className="new-footer">
          <div className="lg:flex   lg:w-[98%] w-[90%] mx-auto py-[40px] new-main-footer ">
            <div className="new-footer-logo">
              <Link to="/">
                <Newfooterlogo />
              </Link>

              {/* Terms & Policy for Mobile */}
              <span className="block sm:hidden">
                {" "}
                {/* Show on small screens, hide on larger */}
                <p
                  className={`${
                    lng === "en" ? "text-[18px]" : "text-[16px]"
                  } leading-6 font-[AraHamah1964] text-white font-medium cursor-pointer`}
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent checkbox toggle
                    navigate("/policy", { replace: true });
                  }}
                >
                  {t("signinPage.acceptTerms2")}
                </p>
              </span>
            </div>

            <div className="sm:mx-[1%] mx-[5%] space-y-2  arabic-footer-link">
              {/* <p className="text-[30px] font-[AraHamah1964] text-white sm:mx-10 new-footer-follow">
              {t("footer.followUs")}
            </p> */}
              <div className="main-demo">
                {/* {SocialLinks.map((link, i) => {
                return ( */}
                <div>
                  {/* <TaxIcon className="min-w-[38px]" /> */}

                  <a target="_blank" rel="noreferrer">
                    {/* {link.icon} */}
                  </a>
                  <p className="text-[22px] font-[AraHamah1964] text-white mx-4">
                    {/* <a href={link.href} target="_blank" rel="noreferrer">
                        {link.text}
                      </a> */}
                  </p>
                </div>
                {/* );
               })} */}
                <div className="demo">
                  <div className="demo-b">
                    {/* <div className="footer-text-icon">
                      <div className="footer-text-icon-flex">
                        <div className="footer-icon-link">
                          <a href="311322093900003">
                            311322093900003
                            <TaxIcon className="min-w-[38px]" />
                          </a>
                        </div>
                        <div className="footer-icon-link">
                          <a href="mailto:mrcarrot.sa@gmail.com">
                            mrcarrot.sa@gmail.com
                            <MailIcon className="min-w-[38px]" />
                          </a>
                        </div>
                        <div className="footer-icon-link">
                          <a
                            href="https://mrcarrot.sa/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            mrcarrot.co
                            <WebIcon className="min-w-[38px]" />
                          </a>
                        </div>
                      </div>
                    </div> */}
                    <div className="footer-icon">
                      <div
                        className="footer-icon-blank"
                        title="info@mrcarrot.sa"
                      >
                        <a href="mailto:info@mrcarrot.sa">
                          <MailIcon className="min-w-[38px]" />
                        </a>
                      </div>

                      {/* <div
                        className="footer-icon-blank"
                        title="https://mrcarrot.sa/"
                      >
                        <a
                          href="https://mrcarrot.sa/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <WebIcon className="min-w-[38px]" />
                        </a>
                      </div> */}

                      {/* <div className="footer-icon-blank">
                        <SnapchatIcon className="min-w-[38px]" />
                      </div> */}
                      <div
                        className="footer-icon-blank"
                        title="https://www.instagram.com/mrcarrot.sa/"
                      >
                        <a
                          href="https://www.instagram.com/mrcarrot.sa/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <InstagramIcon className="min-w-[38px]" />
                        </a>
                      </div>
                      <div
                        className="footer-icon-blank"
                        title="https://www.tiktok.com/@mrcarrot.sa"
                      >
                        <a
                          href="https://www.tiktok.com/@mrcarrot.sa"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <TiktokIcon className="min-w-[38px]" />
                        </a>
                      </div>
                      <div
                        className="footer-icon-blank"
                        title="https://wa.me/966531270787"
                      >
                        <a
                          href="https://wa.me/966531270787"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <WhatsappIcon className="min-w-[38px]" />
                        </a>
                      </div>
                      <div
                        className="footer-icon-blank"
                        title="https://twitter.com/mrcarrotsa"
                      >
                        <a
                          href="https://twitter.com/mrcarrotsa"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <TwitterIcon className="min-w-[38px]" />
                        </a>
                      </div>
                      <div
                        className="footer-icon-blank"
                        title="https://maps.app.goo.gl/8ktEybaFFqUhbwXg6"
                      >
                        <a
                          href="https://maps.app.goo.gl/8ktEybaFFqUhbwXg6"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <LocationIconOrange className="min-w-[38px]" />
                        </a>
                      </div>
                      {/* <div
                        className="footer-icon-blank"
                        title="311322093900003"
                      >
                        <TaxIcon className="min-w-[38px]" />
                      </div> */}
                    </div>

                    <div className="footer-info">
                      <p>{t("footer.head")}</p>
                      <p> {t("footer.subHead")}</p>
                    </div>
                  </div>

                  <div className="footer-text-icon-flex">
                    {/* <div className="footer-icon-link">
                    <a href="311322093900003">
                    311322093900003
                      <TaxIcon className="min-w-[38px]" />
                    </a>
                  </div> */}
                    {/* <div className="footer-icon-link">
                    <a href="mailto:mrcarrot.sa@gmail.com">
                      mrcarrot.sa@gmail.com
                      <MailIcon className="min-w-[38px]" />
                    </a>
                  </div>
                  <div className="footer-icon-link">
                    <a href="https://mrcarrot.sa/" target="_blank" rel="noreferrer">
                    mrcarrot.co
                      <WebIcon className="min-w-[38px]" />
                    </a>
                  </div> */}
                  </div>
                </div>
              </div>
            </div>
            <span className="new-footer-logo-terms hidden sm:block ">
              <p
                className={`${
                  lng === "en" ? "text-[23px]" : "text-[18px]"
                } leading-6 font-[AraHamah1964] text-white font-medium cursor-pointer mx-2`}
                onClick={(e) => {
                  e.stopPropagation();
                  navigate("/policy", { replace: true });

                }}
                
              >
                {t("signinPage.acceptTerms2")}
              </p>
            </span>
          </div>
        </div>
        <div className="flex justify-center py-4 items-center footer-logo">
          <p className="text-[23px] font-[AraHamah1964] text-lightgrey mr-2">
            {t("footer.developedBy")}
          </p>
          <a
            href="https://iplatform.co/"
            target="_blank"
            rel="noreferrer noopener"
            className="text-[23px] font-[AraHamah1964] text-lightgrey"
          >
            <img
              src={"/Partners/developer_logo.png"} // Replace with the actual path or URL of your image
              alt="sfswebtech"
              className="h-6 w-auto" // Adjust the size of the image as needed
            />
          </a>
        </div>
      </footer>
      {/* {showModal ? (
        <Modal
          showModal={showModal}
          setShowModal={() => setShowModal(!showModal)}
          modalClassName="!w-[1300px] !min-h-[185px] pb-8 px-10"
        >
          <TermsPrivacyModalChildren />
        </Modal>
      ) : null} */}
    </>
  );
};

export default withTranslation()(Footer);
